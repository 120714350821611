import "./mobile-menu.css"

import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { CSSTransition } from "react-transition-group"

import { XIcon } from "@heroicons/react/solid"

import MainNav from "./main-nav"

const MobileMenu = (props) => {
    var [showMenu, setShowMenu] = React.useState(0);
    
    React.useEffect(() => {
        var m = true;
        if (m) {
            if (props.show === true) {

                if (showMenu === 0) {
                    setTimeout(() => {
                        setShowMenu(1);
                    }, 500);
                } else if (showMenu === 2) {
                    props.onClose();
                    setShowMenu(0);
                }

                
            }
        }

        return () => {
            m = false;
        }

    }, [props, showMenu])
    const onClose = () => {
        setShowMenu(2);
    }
    return <CSSTransition in={(props.show)} timeout={500} classNames="fade" unmountOnExit>
        <div className="fixed md:fixed flex z-50 min-w-full max-w-full min-h-screen top-0 left-0 bg-gray-700 bg-opacity-90 backdrop-filter backdrop-blur-sm">
            <CSSTransition in={showMenu === 1} timeout={500} classNames="fade-right" unmountOnExit>
                <div className="mobile-menu">
                    <div className="flex flex-row justify-between px-5 items-center border-b border-gray-200">
                        <span><StaticImage layout="fullWidth" style={{ width: 110 }} alt="DFW Logo" src="../../images/dfw-logo-colored@2x.png" /></span>
                        <button onClick={onClose} aria-label="close"><XIcon className="w-8 h-8 text-orange-light" /></button>
                    </div>
                    <MainNav className="mobile-nav" mobile={true} />
                </div>
            </CSSTransition>
        </div>
    </CSSTransition>;
}

export default MobileMenu