import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import MainNav from "./shared/main-nav";
import "./header.css"
import MobileMenu from "./shared/mobile-menu";

const Header = ({colored}) => {

  const [isColored, setColored] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const hdr = React.useRef(null);
  const onScroll = React.useCallback((e) => {
    if (window.scrollY > hdr.current.offsetHeight) {
      setColored(true);
    } else {
      setColored(false);
    }
  }, [])
  React.useEffect(() => {
    var m = true;
    if (m) {
      window.addEventListener("scroll", onScroll);
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
      m = false;
    }
  }, [onScroll]);



  return <>
    <div ref={hdr} className={`hdr w-full z-40 ` + (isColored || colored ? "is-light" : "")}>
      <header className={`2xl:container flex mx-auto justify-between items-start flex-row px-4`}>
        <Link className="logo" to="/">
          <span className={!isColored && !colored ? "block" : "hidden"}><StaticImage layout="constrained" alt="DFW Logo" src="../images/dfw-logo.png" /></span>
          <span className={isColored || colored ? "block" : "hidden"}><StaticImage layout="fullWidth" style={{ width: 148 }} alt="DFW Logo" src="../images/dfw-logo-colored@2x.png" /></span>
        </Link>
        <div className="main-nav-cont">
          <MainNav className="main-nav" />
          <Link className="btn-donate" to="/donate/">Donate</Link>
        </div>
        <button onClick={() => { setShowMenu(true) }} className="mobile-option">
          <span></span>
        </button>
      </header>
    </div>
    <MobileMenu onClose={()=>{setShowMenu(false)}} show={showMenu} />
  </>
}
export default Header
