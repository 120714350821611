import React from "react"
import {Link} from "gatsby"
import "./icon-link.css"
import { SHOP_URL } from "../../constants/config";
const IconLink = () => {
        const menu = [
        { className: 'foster',link: '/foster', label: "Foster" },
        { className: 'volunteer',link: '/volunteer', label: "Volunteer" },
        { className: 'adoption',link: '/adoption', label: "Adopt" },
        { className: 'donate',link: '/donate', label: "Donate" },
        { className: 'shop',link: SHOP_URL, label: "Shop",target:"_blank" },
    ];

    return  <ul className="icon-link">
                {menu && menu.map((item, key) => {
                    return <li key={key} className={item.className}><Link target={item.target?item.target:"_self"} to={`${item.link}`}>{item.label}</Link></li>;
                })}
            </ul>
}

export default IconLink