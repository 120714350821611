import "./main-nav.css"

import React from "react"

import { Link } from "gatsby"

import routes from "../../main-nav.routes"

const MainNav = (props) => {
    const [subMenuState, setSubMenuState] = React.useState(null);

    const setSubMenu = (index) => {
        if (subMenuState === index) {
            setSubMenuState(null);
        } else {
            setSubMenuState(index);
        }
    }

    return <ul className={props.className}>
        {routes && routes.map((main, mkey) => {
            return <li key={mkey} className={main.children ? 'has-child' : ''}>

                {!main.children && !props.mobile && <Link target={main.target?main.target:"_self"} activeClassName="active" to={main.link ? main.link : "#"}>{main.label}</Link>}
                {!main.children && props.mobile && <Link target={main.target?main.target:"_self"} activeClassName="active" to={main.link ? main.link : "#"}>{main.label}</Link>}
                {main.children && !props.mobile && <Link target={main.target?main.target:"_self"} activeClassName="active" to={main.link ? main.link : "#"}>{main.label}</Link>}
                {main.children && props.mobile && <button onClick={() => { setSubMenu(mkey) }}>{main.label}</button>}
                
                {main.children && <><ul className={subMenuState === mkey ? "active" : ""}>
                    {main.children && main.children.map((sub, skey) => {
                        return <li key={skey}><Link activeClassName="active" to={sub.link}>{sub.label}</Link></li>
                    })}
                    
                </ul></>}
            </li>
        })}
        <li className="donate-mobile"><Link activeClassName="active" to={"/donate"}>Donate</Link></li>
    </ul>
}

export default MainNav