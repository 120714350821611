import "./footer.css"

import React from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import IconLink from "./shared/icon-link"
import { SHOP_URL } from "../constants/config"

const Footer = ({layout}) => {
const menu = [
    {link:"",label:"Home"},
    {link:"/available-dogs/",label:"Available Dogs"},
    {link:"/adopted-dogs/",label:"Adopted Dogs"},
    {link:"/stories/",label:"Success Stories"},
    {link:"/donate/",label:"Fundraising"},
    {link:"/special-needs-dogs/",label:"Special Needs Dogs"},
    {link:"/in-memoriam/",label:"In Memoriam"},
    {link:"/privacy-policy/",label:"Privacy Policy"},
    {link:"/terms-and-conditions/",label:"Terms and Conditions"},
    {link:"/refund-policy/",label:"Refund Policy"},
];
const menu2 = [

     {link:"/resources/",label:"Resources"},
     {link:SHOP_URL,label:"Shop",target:"_blank"},
     {link:"/contact/",label:"Contact Us"},
    //  {link:"/upcoming-events/",label:"Upcoming Events"},
];

const menu3  = [
     {link:"https://portal.dfwlabrescue.org/",label:"Login"},
     {link:"https://www.facebook.com/DFWLabradorRescue",label:"Facebook"},
];
 
   return <footer className={layout}>
       <div className="2xl:container mx-auto flex flex-col px-4">
           <div className="pre-footer">
               <Link className="logo" to="/"><StaticImage alt="logo" layout="constrained" src="../images/dfw-logo-footer.png"/></Link>
                <IconLink/>
           </div>
           <div className="footer-cont">
               <div className="footer-l">
                   <h3>Join our Mailing List</h3>
                   <p>You’ll receive newsletters and other emails to help you stay informed about our events, news and volunteer opportunities. (We never spam!)</p>
                   <Link className="btn-secondary" target="_blank" to="https://dfwlabrescue.us1.list-manage.com/subscribe?u=b7dd82f4d096d11092b5e5b82&id=34d710aa1e">Sign-up now!</Link>
               </div>
               <div className="footer-r">
                   <ul className="menu">
                       {menu && menu.map((item,index)=>{
                           return <li key={index}><Link to={item.link}>{item.label}</Link></li>
                       })}
                   </ul>
                   <div className="f-menu">
                       <ul className="menu">
                        {menu2 && menu2.map((item,index)=>{
                           return <li key={index}><Link target={item.target?item.target:"_self"} to={item.link}>{item.label}</Link></li>
                       })}
                       </ul>

                       <ul className="bmenu">
                       {menu3 && menu3.map((item,index)=>{
                           return <li key={index}><Link target="_blank" to={item.link}>{item.label}</Link></li>
                       })}
                       </ul>
                   </div>
               </div>
           </div>
           <div className="footer-text">
               <span className="cp">Copyright {(new Date()).getFullYear() } Dallas / Fort Worth Labrador Retriever Rescue Club All Rights Reserved.</span>
            <span className="by">Website Design by: My Biz Niche</span>
           </div>
       </div>
</footer> 
}

export default Footer