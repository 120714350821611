import { SHOP_URL } from "./constants/config";

const routes = [
    {
        label: "Get Involved", children: [
            { label: "Foster", link: '/foster/' },
            { label: "Volunteer", link: '/volunteer/' },
            { label: "Adoption", link: '/adoption/' },
            { label: "Adoption Application", link: '/adoption-process/' },
            { label: "Think Before You Adopt", link: '/think-before-you-adopt/' },
        ]
    },
    {
        label: "Dogs", children: [
            { label: "Available Dogs", link: '/available-dogs/' },
            { label: "Coming Soon Dogs", link: '/coming-soon-dogs/' },
            { label: "Special Needs Dogs", link: '/special-needs-dogs/' },
            { label: "In Memoriam", link: '/in-memoriam/' },
            { label: "Adopted Dogs", link: '/adopted-dogs/' },
        ]
    },
    { label: "Stories", link: "/stories/" },
    {
        label: "Events", link: "/events/"
        // children: [
        //     { label: "Upcoming Event", link: "#" },
        //     { label: "Run for Retrievers 5K", link: "#" },
        //     { label: "Run for Retrievers Sponsorship Information", link: "#" },
        // ]
    },
    { label: "FAQs", link: "/faqs/" },
    {
        label: "Resources", children: [
            { label: "Vets", link: "/vets/" },
            { label: "Links", link: "/links/" },
        ]
    },
    { label: "Shop", link: SHOP_URL,target:"_blank" },
]
export default routes;